import React from 'react';
import {useEffect} from "react";

function EducationAndExperience() {
  return (
    <div id="Education-Experience">
      <h2>Education and Experience</h2>
      <p>A full copy of my CV can be found here: <a href={"/cv/sheetal-cv.pdf"} target={"_blank"} rel="noopener noreferrer">My CV</a>.</p>
      <p>An abridge version can be found at resume here: <a href={"/cv/sheetal-resume.pdf"} target={"_blank"} rel="noopener noreferrer">My Resume</a> </p>

      <h3>Education</h3>
      <ul>
        <li>
          <strong>Master's Degree in Social Work - Advanced Clinical Practice</strong>
          <br />
          Columbia University, 
          New York City, 
          New York
        </li>
        <li>
          <strong>Bachelor's degree in Clinical Psychology</strong>
          <br />
          University of California - San Diego, 
          San Diego, 
          California
        </li>
      </ul>
      <h3>Experience</h3>
      <ul>
        <li>
          <strong>DBT Clinical Intern</strong>
          <br />
            Manhattan Alternative Wellness Collective, New York City, New York
          <br />
          (2022-Current)
        </li>
        <li>
          <strong>Clinical Social Work Intern </strong>
          <br />
            Reproductive Medicine Associates, New York City, New York
          <br />
          (2021-2022)
        </li>
      </ul>
    </div>
  );
}

export default EducationAndExperience;
