import React from 'react';
import {useEffect} from "react";

function Contact(){
    useEffect(() => {

        // update the title when the page changes
        document.title = `Contact Me - SheetalMallavarapu.com`;
    },[]);
  return (
    <section>
      <h1>Contact</h1>
      <p>To reduce spam, my contact information is provided in my resume.</p>
        <a href={"/cv/sheetal-resume.pdf"} target={"_blank"} rel="noopener noreferrer">Click here</a>


    </section>
  );
}

export default Contact;
