import React from 'react';
import {useEffect} from "react";
import "./styles.css"

function Yoga(){
    useEffect(() => {

        // update the title when the page changes
        document.title = `Yoga Classes - SheetalMallavarapu.com`;
    },[]);
    return(
        <section id={"Yoga"}>
            <h1>Yoga Schedule</h1>
            <h2> Current Classes</h2>
            <p>My current schedule can be found at: <a target="_blank" href={"https://alchemysticstudio.com/#offerings"}>Alchemystic Studio</a>.</p>
            <p>Please contact me for individual or group rates.</p>

            <p>Please note I am currently teaching remotely part time.</p>
            <h2>About My Yoga Practice</h2>
            <p>
                As a yoga teacher, I am passionate about helping individuals connect with their bodies, minds,
                and spirits through the practice of yoga.
                I strive to create a welcoming and inclusive environment where my students can explore their
                physical and mental capabilities, cultivate mindfulness, and deepen their understanding of themselves.
            </p>
            <p>
                However, I ask that you do not engage in my yoga classes without first
                consulting with your mental health care provider if you are currently under treatment by another mental health professional.
                While yoga can be a helpful complement to therapy, it is not a substitute for professional mental health treatment.
            </p>


            <p> I am dedicated to providing a safe and nurturing space for all individuals to experience the
                transformative power of yoga, and I am committed to continually expanding my knowledge and
                skills to better serve my students' needs.</p>

            <h3>Disclaimer</h3>
            <p>
                As a healthcare provider, it is important for me to maintain professional boundaries with my
                clients to ensure their safety and well-being.
                Therefore, I must emphasize that my own clients should not engage in my yoga practice.
                While I believe in the benefits of yoga as a complementary practice to healthcare,
                I must maintain a clear distinction between my roles as a healthcare provider and yoga teacher.
                If you are a current client of mine, I encourage you to discuss any interest in yoga or other
                physical practices with me during a scheduled appointment to determine the
                best course of action for your specific needs.
                Thank you for understanding the importance of these professional boundaries.
            </p>
        </section>
    );
}
export default Yoga;