import React from 'react';
import "./styles.css"
import {useEffect} from "react";

function LandingPage() {
    useEffect(() => {

        // update the title when the page changes
        document.title = `Welcome! - SheetalMallavarapu.com`;
    },[]);
  return (
    <section id={"Landing-Page"}>
      <h1>Welcome!</h1>
        <p>Hello, my name is Sheetal and I am currently based in New York City.</p>
        <p>
            As a pre-licensed mental health therapist (MSW Candidate 2023),
            I have always had a strong desire to help individuals navigate and
            overcome the challenges they face in their lives.
            I am passionate about creating a safe and supportive environment for
            my clients to express their emotions, explore their thoughts and beliefs,
            and develop effective coping strategies.
            My goal is to empower my clients to take control of their mental health and well-being,
            and to assist them in realizing their full potential.
            Through my dedication, empathy, and professional training, I am committed
            to making a positive impact on the lives of those who seek my guidance.
        </p>
      <p>On my website, you can learn more about my services, my education and experience, and how to contact me for an appointment.
      You may also visit some of my other non therapy interests, namely teaching and practicing yoga.</p>
      <p>Thank you, I look forward to working with you.</p>
    </section>
  );
}

export default LandingPage;
