import React from 'react';
import SocialMediaNav from '../../SocialMediaNav';
import './styles.css'

function Footer() {
  return (
    <footer>
      <p>Copyright © {new Date().getFullYear()} SheetalMallavarapu.com</p>
      <SocialMediaNav />
    </footer>
  );
}

export default Footer;