import React from 'react';
import './styles.css'

function SocialMediaNav() {
  return (
    <social>
        <li>
            <a class="icon brands fa-linkedin-in" href="https://www.linkedin.com/in/sheetalmallavarapu/"></a>
        </li>
    </social>
  );
}

export default SocialMediaNav;
