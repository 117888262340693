import React from 'react';
import { Link } from 'react-router-dom';

function Menu() {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-me">About Me</Link>
          </li>
          <li>
            <Link to="/my-services">My Services</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to={"yoga"}>Yoga</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Menu;
