import React, {useEffect} from 'react';
import profilePlaceholder from './profilePlaceholder.png';
import EducationAndExpereince from '../EducationAndExperience';
import './styles.css'

function AboutMe() {
    useEffect(() => {

        // update the title when the page changes
        document.title = `About Me - SheetalMallavarapu.com`;
    },[]);
  return (
    <section id={"about-met"}>
      <h1>About Me</h1>
      <div class="About-Me">
        <div>
          <p> I am graduating with an MSW from Columbia in May 2023 with an emphasis on Advanced Clinical Practice.
              During this time, I was in Dialectical Behavior Therapy(DBT) Intensive Training Program at Columbia University.
              </p>
            <p>
              In my spare time, I am 200 hour certified yoga teacher and I am currently teaching yoga remotely at "Alchemystic Studio".
          </p>
        </div>
        <div>
          <img src='/images/sheetal-photos/my-photo.jpeg'
               height="400px"
               style={{objectfit : "scale-down"}}
               alt="Profile" />
        </div>
      </div>
      <EducationAndExpereince/>
    </section>
  );
}

export default AboutMe;
