import React from 'react';
import './App.css';
import Main from './components/Main'
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import { BrowserRouter,Route,Navigate } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";


function App() {
  return (
    <div className='App'>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop/>
        <Header />
        <Main />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
