import Menu from '../../Menu';

function Header() {
    return (
      <header>
        <Menu />
      </header>
    );
  }
  
  export default Header;