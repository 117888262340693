import React from 'react';
import {useEffect} from "react";
import './styles.css'

function MyServices() {
    useEffect(() => {

        // update the title when the page changes
        document.title = `My Services - SheetalMallavarapu.com`;
    },[]);
  return (
      <section>
      <h1>My Services</h1>
        <h2>
            Primary Areas of Focus
        </h2>
        <p>
            While I have a variety of experience counseling many walks of life, my focuses are the following populations:
            <ul>
                <li>Veterans </li>
                <li>Adolescents/Young Adults</li>
                <li>LGBTQ+ populations</li>
                <li>Many other marginalized populations not formally described (e.g. immigrant populations, non-traditional relationships)</li>
            </ul>
            In addition, I provide the following types of care:
            <ul>
                <li>Dialectical-Behavior Therapy</li>
                <li>Evidence-Based Interventions</li>
                <li>Cognitive-Behavioral Interventions</li>
                <li>Infertility And Reproductive Health</li>
                <li>Family Dynamics</li>
                <li>Trauma-Informed Care</li>
                <li>Mindfulness Interventions & Alternative Treatments</li>
                <li>Culturally Sensitive Care</li>
                <li>Grief Therapy</li>
                <li>Substance Use</li>
            </ul>
        </p>
      </section>
  );
}

export default MyServices;
