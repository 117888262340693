import React from 'react';
import './index.css'

import Header from '../common/Header';
import Footer from '../common/Footer';
import Routing from '../../routing';

function Main() {
  return (
      <div className="Main">
        <Routing />
      </div>
  );
}

export default Main;