import React from 'react';
import { BrowserRouter, Route, Navigate,Routes } from 'react-router-dom';
import Menu from '../components/Menu';
import AboutMe from '../components/AboutMe';
import MyServices from '../components/MyServices';
import Contact from '../components/Contact';
import LandingPage from '../components/LandingPage';
import PageNotFound from '../components/PageNotFound';
import Yoga from "../components/Yoga";

export default function Routing() {
  return (
    <Routes>
        <Route path='/' exact element={<LandingPage/>}/>
        <Route path="/About-Me" element={<AboutMe/>} />
        <Route path="/My-Services" element={<MyServices/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path={"Yoga"} element={<Yoga/>}/>
        <Route path="PageNotFound" element={<PageNotFound/>}/>
        <Route path = "*" element={<Navigate to="/PageNotFound" replace/>}/>
    </Routes>
  );
}



